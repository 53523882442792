.container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .addButton {
    align-items: center;
    background-color: transparent;
    color: grey;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: opacity 0.5s ease;
  }
  
  .addButton:hover {
    opacity: 0.5;
    background-color:transparent;
  }
  
  .routesTable {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
  }
  
  .routesTable table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .routesTable th, .routesTable td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
  
  .routesTable th {
    background-color: #f4f4f4;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 85vh;
    width: 80%;
    z-index: 9999;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
  }
  
  .previewTable {
    margin-top: 20px;
    overflow-y: auto;
    margin-bottom: 20px;
    height: 55vh;
    overflow-y: auto;
  }
  
  .previewTable table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .previewTable th, .previewTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;

  }
  
  .previewTable th {
    background-color: #f4f4f4;
  }
  
  .modalActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .confirmButton {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirmButton:hover {
    background-color: #218838;
  }
  
  .cancelButton {
    background-color: #dc3545;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancelButton:hover {
    background-color: #c82333;
  }
  
  .addRowButton {
    color: #0056b3;
    background-color: transparent;
    font-weight: bold;
    padding: 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.5s ease;
    display: flex;
    font-size: 16px;
    align-items: center;
    gap: 8px;
  }
  

  .addRowButton:hover {
    opacity: 0.5;
    background-color: transparent;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .uploadButton {
    font-weight: bold;
    background-color: #0a5f23;
    color: white;
    padding: 8px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.5s ease;
  }

  .uploadButton:hover {
    background-color: #0a5f23;
    opacity: 0.5;
  }

  .deleteButton {
    background-color: transparent;
    border-radius: 50px;
    padding: 1px;
    transition: background-color 0.5s ease;
    cursor: pointer;
  }

  .deleteButton:hover {
    background-color: #e9e9e9;
  }