.iconButton {
    width: fit-content;
    font-size: 1.5rem;
    padding: 0;
    margin-top: 0;
    margin-bottom: 2px;
    color: black;
    background: none;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .iconButton:hover {
    background: none !important;
    opacity: 0.5;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: background-color 0.5s ease;
  }
  
  .modalOverlay.open {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .modal.open {
    opacity: 1;
  }
  
  h2{
  text-align: left;
  font-weight: bold;
  }
  
  p{
    margin-top: 15px;
    text-align: left;
  }

  .modalContent {
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: right;
  }
  
  .confirmButton {
    margin-left: 10px;
    padding: 8px 16px;
    background-color: #c70000 !important;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 100px;
    cursor: pointer;
    transition: opacity 0.5s ease;

  }

  .confirmButton:hover {
    opacity: 0.5;
  }
  
  .cancelButton {
    padding: 8px 12px;
    background-color: #ccc !important;
    color: #333;
    border: none;
    border-radius: 10px;
    width: 100px;
    cursor: pointer;
    transition: opacity 0.5s ease;
  }

  .cancelButton:hover {
    opacity: 0.5;
  }
  