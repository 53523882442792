.fuelContainer {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    width: calc(100vw - 140px);
  }
  
  .leftSection {
    width: 50%;
    float: left;
    height: calc(100vh - 175px);
    overflow-y: auto;
  }

  .rightSection {
    width: 50%;
    float: right;
  }

  .divider {
    margin-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }
  
  .section {
    padding: 15px;
    border-radius: 8px;
  }
  
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }
  
  .field {
    margin-bottom: 10px;
  }
  
  .label {
    font-size: 14px;
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    background: #f5f5f5;
    border: none;
    border-bottom: 2px solid #cecece;
    border-radius: 0px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .input:focus {
    border-color: #d15533;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .button {
    padding: 8px 16px;
    font-size: 16px;
    color: #fff;
    background-color: #d15533;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.5s ease;
  }
  
  .button:hover {
    opacity: 0.5;
    background: #d15533;
  }
  
  .message {
    margin-top: 10px;
    color: #ad2828;
    font-size: 20px;
    font-weight: bold;
  }
  
  .dateList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dateItem {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5px;
    background-color: #f9f9f9;
    font-size: 14px;
  }
  
  .priceDateContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
  
  .dateColumn {
    flex: 1;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .dateColumn h3 {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .dateColumn h3 svg {
    margin-right: 8px;
  }
  
  .dateList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .dateList li {
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.5s, color 0.5s;
  }
  
  .dateList li:hover {
    background-color: #cccccc;
  }

  .dateList li.selected {
    font-weight: bold;
    background-color: #6e6e6e;
    color: white;
  }
  
  .dateColumn div {
    color: #888;
    font-style: italic;
  }