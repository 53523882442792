.viewRoutesContainer {
  width: 100%;
  margin-top: 20px;
  height: calc(100vh - 72px);
  margin-bottom: 100px;
}

.viewRoutesContainerTitle {
  font-size: 14px;
  text-align: left;
  padding: 15px;
  padding-left: 40px;
  padding-right: 0px;
}

.viewRoutesOverview { 
  width: 100%;
  display: flex;
  direction: column;
}

.leftContainer {
  box-shadow: inset 0px 3px 10px -5px rgba(0, 0, 0, 0.25);
  height: calc(100vh - 72px);
  padding-top: 15px;
  width: 60%;
  display: flex;
  justify-content: center;
}

.actionButtonContainer {
  display: flex;
  justify-content: right;
}

.routeCardsContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.rightContainer {
  width: 60%;
  height: calc(100vh - 72px);
}

.viewRoutesMainContainer {
  width: 95%;
}

.viewRoutesMainContainerTitle {
  font-size: 16px;
}


.viewRoutesDivider {
  height: 1px;
  width: 100%;
  background-color: #E47712;
  margin: 10px auto; 
  opacity: 0.5;
}


.left {
  text-align: left;
  flex: 1;
  align-content: center;
}

.center {
  text-align: left;
  flex: 1;
  align-content: center;
}

.right {
  display: flex;
  text-align: center;
  flex: 1;
  transition: color 0.5s ease;
  align-content: center;    
}

.configureButton {
  background-color: #174A3F; 
  color: white;
  padding: 6px 25px; 
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  transition: opacity 0.5s ease;
  font-weight: bold;
  width: 150px;
  height: fit-content;
}

.configureButton:hover {
  opacity: 0.5;
}

.routesMatrix {
  margin-left: 10px;
  width: 220px;
}

.viewRoutesMatrixContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
}

.routeManageButton {
  background-color: #E47712; 
  color: white;
  padding: 6px 25px; 
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  transition: opacity 0.5s ease;
  font-weight: bold;
  width: 180px;
  height: fit-content;
  margin-left: 10px;
}

.routeManageButton:hover {
  opacity: 0.5;
}

.viewRoutesAddressTitle {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.viewRoutesAddressContainer {
  font-size: 14px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 15px;
}

.viewRoutesAddressLine {
  display: flex;
  justify-content: space-between;
  margin: 15px 5px;
  border-bottom: 1px solid #efefef;
}

.viewRoutesLabel {
  color: #E47712;
  font-weight: bold;
}

.selectDateText{
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.selectDateDropdown {
  padding: 6px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  outline: none;
  cursor: pointer;
  transition: border-color 0.5s;
  margin-left: 15px;
}

.selectDateDropdown:focus {
  border: 2px solid #000000;
}
.actionMessage{
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}

.graphContainer {
  width: 100%;
  background: white;
  padding: 10px;
  border-radius: 15px;
}

.routeDetailsContainer {
  display: flex;
  justify-content: space-between;
}


.toggleButton {
  height: 50px;
  width: 50px;
  background-color: white;
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
  border-radius: 50px;
}

.toggleButton:hover {
  background-color: grey; 
}

.delayedOpacity {
  opacity: 0; 
  animation: showOpacity 1.5s 1.5s forwards; 
}

@keyframes showOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin-top: 5px;
}

.filterContainer label {
  font-size: 16px;
  margin-right: 10px;
}

.searchContainer {
  width: 50%;
}

.searchContainer input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.sortContainer {
  display: flex;
  justify-content: left;
  width: 20%;
  gap: 10px;
}

.sortContainer button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  height: fit-content;
  width: fit-content;
  border-radius: 25px; 
  transition: background-color 0.5s ease;
}

.sortContainer button.active {
  background-color: rgba(128, 128, 128, 0.1);

}

.fileListContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.selectDateDropdown {
  width: 50%;
  font-size: 16px;
  border-radius: 5px;
}
.selectPriceColDropdown{
  width: 25%;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.radiusInputGroup {
  width: 25%;
  display: flex;
  flex-direction: column;
  height: 50px;
}

.radiusInput{
  width: 100% !important;
  padding: 10px !important;
  font-size: 16px;
}

.dateContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5; 
}
.priceFilterDropdown {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.filterButton {
  background-color: #0245be;
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterButton:hover {
  background-color: #023a94;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
  border-radius: 4px;
  min-width: 200px;
  transform: translateX(-70%); 
}

.priceFilterDropdown:hover .dropdownContent {
  display: block;
}

.sliderGroup {
  margin: 10px 0;
}

.sliderGroup label {
  font-size: 12px;
  color: #333;
  margin-bottom: 4px;
  display: block;
}

.sliderGroup input[type="range"] {
  width: 100%;
  margin-top: 4px;
}