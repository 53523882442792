.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: black;
  color: white;
  height: 50px;
  z-index: 1000; /* Ensure the footer stays on top */
}

.language-toggle-container {
  display: flex;
  align-items: center;
  width: 33%;
}

.language-toggle {
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  text-transform: uppercase;
}

.language-toggle.selected {
  border: none;
  background-color: white;
  color: black;
}

.language-toggle.not-selected {
  opacity: 0.5;
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
}

.contact-info {
  margin-right: auto;
  margin-left: -20px;
}

.copyright-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 33%;
}

.copyright-info a {
  color: white;
  text-decoration: none;
}