.settingsMainContainer {
    display: flex;
    width: 100%;
    height: calc(100vh - 72px);
    overflow: auto;
    padding: 25px;
    text-align: left;
    margin-top: 35px;
  }
  
  .tablist {
    list-style-type: none; 
    border-bottom: 1px solid #d8d8d8;
    padding: 10px;
    display: flex; 
    align-items: center;
}

.tab {
    cursor: pointer;
    font-weight: bold;
    border-radius: 10px;
    padding: 8px 12px;
    margin-left: 15px;
    margin-right: 10px; 
    opacity: 0.5;
    transition: opacity 0.5s ease;
    border: none;
}

.tabContent {
    display: flex;
    align-items: center;
}
  
.selected {
    border: none;
    opacity: 1;
}

.tabDivider {
    margin: 0 10px;
    color: #d8d8d8;
}