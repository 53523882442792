.dispatchContainer {
    width: 100%;
    margin-top: 40px;
    height: 100vh;
    overflow: auto;
}

.headerContainer {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.tableContainer{
    width: "100%";
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    align-items: center;
    gap: 20px;
}

.routesOverview {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.columnSelectorDropdown {
    position: absolute;
    top: 120px;
    right: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
    width: 220px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-height: 50vh;
    overflow-y: auto;
}

.columnSelectorDropdown label {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease;
}

.columnSelectorDropdown label:hover {
    background-color: #f0f0f0;
}

.columnSelectorDropdownInput {
    margin-right: 10px;
}

.deleteButton {
    margin-top: 12px;
    font-size: 16px;
    background-color: #c00000;
    font-weight: bold;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

input[type="checkbox"] {
    margin-right: 5px;
    margin-top: 10px;
    width: 18px;
    height: 18px;
}

.automationButton {
    margin-top: 10px;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}

.automationButton:hover {
    background-color: #005bb5;
}

.columnSelectorButton {
    background: none;
    border: none;
    margin-top: -3px;
    font-size: 20px;
    cursor: pointer;
}

.columnSelectorButton:hover {
    background: none !important;
    opacity: 0.5;
}

.tablist {
    list-style-type: none; 
    border-bottom: 1px solid #d8d8d8;
    padding: 10px;
    display: flex; 
    align-items: center;
}

.tab {
    cursor: pointer;
    font-weight: bold;
    border-radius: 10px;
    padding: 8px 12px;
    margin-left: 15px;
    margin-right: 10px; 
    opacity: 0.5;
    transition: opacity 0.5s ease;
    border: none;
}

.tabContent {
    display: flex;
    align-items: center;
}
  
.selected {
    border: none;
    opacity: 1;
}

.tabDivider {
    margin: 0 10px;
    color: #d8d8d8;
}