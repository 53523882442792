/* AssetsTable Container */

  /* Header */
  .containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .headerTitleHovered {
    color: grey;
  }
  
  /* Grid Container */
  .gridContainerEnv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .gridWrapper {
    width: 75%;
    min-height: 264px;
    margin-right: 20px;
  }
  
  
  /* Total Display */
  .gwpTotalEnv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
  }
  
  .gwpTotalText {
    font-weight: 600;
  }
  
  .gwpTotalNumber {
    font-weight: 700;
    color: #6b9f6c;
    transition: color 0.3s ease;
  }
  
  /* Asset Selection */
  .assetSelectionContainer {
    margin-left: 10px;
    width: 25%;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 3px;
    border: 1px solid #bababa;
    height: 270px;
    overflow-y: auto;
  }
  
  .assetSelectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .availableAssets {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  /* Asset Item Styling */
  .assetItem {
    padding: 8px 12px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .assetItemHovered {
    background-color: #eaeaea;
  }
  
  .assetItemSelected {
    background-color: #6b9f6c;
    font-weight: 600;
    color: white;
    transition: background-color 0.3s ease, color 0.3s ease, font-weight 0.3s ease;
  }
  
  /* Button Container */
  .buttonContainerEnv {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
  
  /* Loading Spinner */
  .loadingSpinner {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
  }
  
  .selectionButtons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .selectButton,
  .deselectButton {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #ccc;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #7a7a7a;
    color: white;
    font-weight: bold;
  }
  
  .selectButton:hover {
    opacity: 0.5;
    border-color: #bbb;
  }
  
  .deselectButton:hover {
    opacity: 0.5;
    border-color: #bbb;
  }
  