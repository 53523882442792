.container {
    padding: 20px;
    margin-top: -50px;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .filters {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    align-items: center;
  }
  
  .filters input {
    padding: 5px;
    font-size: 16px;
  }
  
  .filters button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .filters button:hover {
    background-color: #218838;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  
  .pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination span {
    font-size: 16px;
    font-weight: bold;
  }
  