.routesMatrix {
  border-radius: 5px;
  width: 100%;
  border: none;
}

.matrixCell {
  width: fit-content;
}

.cellContent {
  width: 200px;
  font-size: 15px;
  color: #434343;
  background-color: rgb(0, 0, 0, 0.05);
  margin: 8px 8px;
  padding: 15px;
  direction: row;
  border-radius: 15px;
}

.cellContent:hover {
  background-color: rgb(0, 0, 0, 0.1);
  cursor: pointer;
}

.cellContent.selected {
  background-color: #FF7B00;
  color: #FFFFFF;
  font-weight: bold;
}

.matrixLegend {
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: #434343;
  font-size: 14px;
}

.legendItem {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.legendCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 30px;
}

.legendCircle.green {
  background-color: #1A8C2C;
}

.legendCircle.orange {
  background-color: #FF7B00;
}

.legendCircle.grey {
  background-color: #5B5B5B;
}

.cellErrorContent{
  font-size: 14px;
  font-weight: bold;
  color: #434343;
  background-color: rgb(0, 0, 0, 0.05);
  margin: 8px 8px;
  padding: 30px;
  direction: row;
  border-radius: 15px;
}

.containerErrorText {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}