.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-left: 30px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: calc(100% - 60px); 
}

.action-buttons button {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-weight: bold;
  transition: opacity 0.5s ease;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.action-buttons button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.export-button {
  background-color: #4caf50;
  color: white;
  transition: opacity 0.5s ease;
}

.export-button:hover {
  background-color: #45a049;
  opacity: 0.5;
}

.dropdown-button {
  background-color: #757575;
  color: white;
  transition: opacity 0.5s ease;
}

.dropdown-button:hover {
  background-color: #757575;
  opacity: 0.5;
}

.calculate-button {
  background-color: #00437a;
  color: white;
  transition: opacity 0.5s ease;
}

.calculate-button:hover {
  background-color: #00437a;
  opacity: 0.5;
}

.recalculate-button {
  background-color: #00437a;
  color: white;
  transition: opacity 0.5s ease;
}

.recalculate-button:hover {
  background-color: #00437a;
  opacity: 0.5;
}

.assign-button {
  background-color: #d15533;
  color: white;
  transition: opacity 0.5s ease;
}

.assign-button:hover {
  background-color: #d15533;
  opacity: 0.5;
}

.dropdown-container {
  position: relative;
}

.dropdown {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.customRouteLabel {
  font-size: 14px;
  align-items: center;
}

.google-link-container {
  margin-top: 10px;
}

.google-link-container input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 500px !important;
  margin: 0 !important;
  height: 25px !important;
}

.user-route {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
}

.user-route label {
  margin-top: 10px;
  margin-right: 10px;
}

.customRouteCheckbox{
  
}

.userRouteLabel {
  margin-left: -10px !important;
  margin-top: 10px !important;
  font-size: 12px;
}

.dropdown-menu {
  position: absolute;
  top: -300px; 
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  width: 300px;
  z-index: 10;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.form-group input {
  width: 100% !important;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.action-buttons-group {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.apply-button {
  background-color: #4caf50;
  color: white;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 5px; 
  transition: opacity 0.5s ease;
}

.apply-button:hover {
  background-color: #45a049;
}

.sms-section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  margin-left: auto;
  align-items: center;
}

.send-sms-button {
  background-color: #d15533;
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  transition: opacity 0.5s ease;
}

.send-sms-button:hover {
  background-color: #d15533;
  opacity: 0.5;
}

.sms-section input {
  width: 200px;
  padding: 7px;
  font-size: 14px;
  border: 1px solid #757575;
  border-radius: 5px;
}

.duplicate-button {
  background-color: #d15533;
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  transition: opacity 0.5s ease;
}

.duplicate-button:hover {
  background-color: #d15533;
  opacity: 0.5;
}

.revert-button {
  background-color: #00437a;
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  transition: opacity 0.5s ease;
}

.revert-button:hover {
  background-color: #00437a;
  opacity: 0.5;
}

.save-button {
  background-color: #4CAF50; 
  border: none;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: opacity 0.5s ease; 
}

.save-button:disabled {
  background-color: #ccc; 
  cursor: not-allowed;
}

.save-button:hover {
  opacity: 0.5; 
}

.save-button:active {
  background-color: #45a049;
}

.warningBox {
  display: flex;
  align-items: center;
  background-color: #ffe6e6;
  border: 1px solid #ff4d4d;
  border-radius: 5px;
  padding: 8px;
  color: #d32f2f;
  font-size: 14px;
  font-weight: bold;
}

.warningBox .icon {
  margin-right: 10px;
  font-size: 20px;
  color: #d32f2f;
}