.organizationContainer {
    font-family: Arial, sans-serif;
    margin: 0 auto;
  }

  .divider {
    margin:0 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }
  
  .section {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
  }
  
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }
  
  .field {
    margin-bottom: 10px;
  }
  
  .label {
    font-size: 14px;
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .input, .textarea {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    background: #f5f5f5;
    border: none;
    border-bottom: 2px solid #cecece;
    border-radius: 0px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .input:focus, .textarea:focus {
    border-color: #d15533;
  }
  
  .actions, .button {
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .button {
    padding: 8px 16px;
    font-size: 16px;
    color: #fff;
    background-color: #d15533;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    transition: opacity 0.5s ease;
  }
  
  .button:hover {
    opacity: 0.5;
    background-color: #d15533;
  }
  