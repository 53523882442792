.main-table-contain {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px auto;
    position: center;
    text-align: left;
    margin-top: 90px;
}

.circularity-active .main-table-contain {
    margin: 5px auto;
}

.main-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 20px;
    background-color: #F7F7F7;
}


.table-title h2 {
    text-align: left; /* Align text to the left */
    padding-left: 20px; /* Adds padding to separate text from the edge */
    margin-top: 0; /* Removes default margin */
    margin-bottom: 20px; /* Space below the title */
    color: #333; /* Text color */
}

.container-header-main {
    display: flex;
    text-align: left; /* Align text to the left */
    padding-left: 0px; /* Adds padding to separate text from the edge */
    margin-top: 0; /* Removes default margin */
    color: #333; /* Text color */
    font-size: 12px;
    justify-content: space-between;
}

.checkbox-container-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.checkbox-container-bottom {
    display: flex;
    align-items: center; /* Vertically center the checkboxes and labels */
    margin-top: 20px;
    margin-bottom: 10px;
}

.checkbox-container-bottom > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkbox-container-bottom input[type="checkbox"] {
    accent-color: #6ba06c; /* Customize the checkbox color (Modern browsers support this) */
    transform: scale(1.2); /* Increase the size of the checkboxes */
    margin-right: 5px; /* Space between checkbox and its label */
    margin-top: 0;
}

.checkbox-container-bottom label {
    font-size: 14px; /* Adjust label text size */
    color: #333; /* Change label text color */
    cursor: pointer; /* Show pointer on hover to indicate clickability */
    transition: color 0.3s ease; /* Smooth transition effect on hover */
}

.checkbox-container-bottom label:hover {
    color: #6ba06c; /* Change label color on hover to match the checkbox accent */
}


.label, label {
    margin-right: 5px; /* Adjusted to bring labels closer to checkboxes */
}

.grid-container {
    height: auto; /* Adjustable height based on content */
    width: 100%; /* Full width of the grid */
    overflow-x: auto; /* Allows horizontal scrolling if necessary */
}

.ag-theme-alpine {
    height: auto; /* Ensure height is auto to fit content */
    width: 100%;/* Full width of the container */
}

.content-container {
    display: flex;
    justify-content: space-between;
}

.left-panel {
    flex: 3;
    max-height: 100%;
}

.toggle-button {
    padding: 0%;
    margin-top: 2px;
}

.details-container {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px;
    position: relative;
    text-align: left;
}

.tab-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    cursor: pointer;
}

.tab-header span {
    margin-right: 20px;
    padding-bottom: 5px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
}

.tab-header span.active {
    border-bottom: 2px solid #333;
}

.ag-header-container {
    background-color: #f0f0f0;
}

.ag-header-viewport {
    background-color: #f0f0f0;
}

.table-top-button-container {
    display: flex;
    justify-content: space-between; /* Ensures search bar stays on the left, buttons on the right */
    align-items: center;
    margin-bottom: 10px;
}

.search-bar-container {
    display: flex;
    align-items: center;
}

.right-buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.show-column-button, .search-button, .print-button, .csv-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #999; /* Lighter grey color */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.show-column-button:hover, .search-button:hover, .print-button:hover, .csv-button:hover {
    color: #555; /* Darker grey color on hover */
}

.columns-modal {
    position: absolute;
    top: 200px; /* Adjust the position to your need */
    right: 75px; /* Adjust the position to your need */
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 300px; /* You can adjust this width */
}

.columns-modal div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.columns-modal input[type="checkbox"] {
    margin-right: 10px; /* Spacing between checkbox and label */
}

.columns-modal input[type="checkbox"] {
    accent-color: #6ba06c; /* Customize the checkbox color (Modern browsers support this) */
    transform: scale(1.2); /* Increase the size of the checkboxes */
    margin-right: 5px; /* Space between checkbox and its label */
}

.columns-modal label:hover {
    color: #6ba06c; /* Change label color on hover to match the checkbox accent */
}

.close-modal-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.toggle-switch {
    position: relative;
    display: flex;
    width: 150px;
    height: 40px;
    background-color: #F4F4F4;
    border-radius: 10px;
    cursor: pointer;
}

.toggle-option {
    flex: 1;
    text-align: center;
    line-height: 40px;
    color: #666666;
    font-weight: bold;
    z-index: 1;
    user-select: none;
    transition: color 0.3s;
}

.toggle-option.selected {
    color: #666666;
}

.toggle-slider {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 70px;
    height: 30px;
    background-color: #DEE9DE;
    border-radius: 7px;
    transition: transform 0.3s ease;
    z-index: 0;
}

.toggle-switch.supplier .toggle-slider {
    transform: translateX(0);
}

.toggle-switch.utilizer .toggle-slider {
    transform: translateX(75px);
}