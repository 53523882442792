.configContainer {
    position: relative;
  }
  
  .configButton {
    align-items: center;
    background-color: transparent;
    color: grey;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: opacity 0.5s ease;
  }
  
  .configButton:hover {
    opacity: 0.5;
  }
  
  .dropdownContent {
    width: 500px;
    background-color: #fff;
    margin-top: 10px;
    padding: 20px;
    border-radius: 8px;
    position: absolute;
    top: 100%;
    left: 80%;
    transform: translateX(-90%);
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .closeIcon {
    cursor: pointer;
    color: #8a8a8a;
  }
  .filterDateContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
  }

  .filterContainer {
    display: flex;
    justify-content: space-between;
    width: 20%;
  }
  
  .filterContainer label {
    font-size: 16px;
    margin-right: 10px;
  }
  
  .searchContainer {
    width: 50%;
  }
  
  .searchContainer input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
  }
  
  .sortContainer {
    display: flex;
    justify-content: left;
    width: 20%;
    gap: 10px;
  }
  
  .sortContainer button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    height: fit-content;
    width: fit-content;
    border-radius: 25px; 
    transition: background-color 0.5s ease;
  }
  
  .sortContainer button.active {
    background-color: rgba(128, 128, 128, 0.1);

  }
  
  .fileListContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  .selectDateDropdown {
    width: 50%;
    font-size: 16px;
    border-radius: 5px;
    height: 35px;
    border: 1px solid #ccc;
  }
  
  .selectPriceColDropdown{
    width: 25%;
    font-size: 16px;
    border-radius: 5px;
    height: 35px;
    border: 1px solid #ccc;
  }

  .radiusInputGroup {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 50px;
  }
  
  .radiusInput{
    width: 100% !important;
    padding: 10px !important;
    font-size: 16px;
  }

  .dateContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .modalActions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .applyConfigButton,
  .calculateRoutesButton {
    padding: 10px 15px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .applyConfigButton {
    background-color: #007bff;
  }
  
  .calculateRoutesButton {
    background-color: #366699;
  }
  
  .reserveInputGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .apply-button {
    background-color: #4caf50;
    color: white;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 5px; 
    transition: opacity 0.5s ease;
  }
  
  .apply-button:hover {
    background-color: #45a049;
  }

  .form-group {
    margin-bottom: 10px;
    justify-content: left;
  }
  
  .form-group label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .form-group input {
    width: 50% !important;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px; 
    text-align: left;
    display: block; 
  }

  .action-buttons-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .calculate-button {
    background-color: #00437a;
    color: white;
    transition: opacity 0.5s ease;
  }
  
  .calculate-button:hover {
    background-color: #00437a;
    opacity: 0.5;
  }
  
  .recalculate-button {
    background-color: #00437a;
    color: white;
    transition: opacity 0.5s ease;
  }
  
  .recalculate-button:hover {
    background-color: #00437a;
    opacity: 0.5;
  }
  
  .assign-button {
    background-color: #d15533;
    color: white;
    transition: opacity 0.5s ease;
  }
  
  .assign-button:hover {
    background-color: #d15533;
    opacity: 0.5;
  }
  
 