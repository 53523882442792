.absolute-target-section {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;
    margin: 30px 0 20px;
}

.net-zero-tool-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-top: 0;
    color: #333;
    font-size: 12px;
    position: relative;
}

.tool-version-tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 16px;
}

.tool-version-tab {
  padding: 10px 20px;
  border: none;
  background: #f1f1f1;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s ease;
}

.tool-version-tab.active {
  background: #6ba06c;
  color: white;
  font-weight: bold;
}

.tool-version-tab:not(.active):hover {
  background: #ddd;
}

.section {
    margin-bottom: 15px;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ddd;
}

h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #222;
}

.data-table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.table-header {
    font-weight: bold;
    background-color: transparent;
    padding: 10px 0;
    text-align: center;
}

.header-column {
    flex: 1;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
}

.table-row {
    display: flex;
    align-items: center;
    padding: 10px;
}

.label {
    flex: 2;
    font-size: 14px;
    font-weight: bold;
}

.input {
    flex: 3;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
}

.input.yellow {
    background-color: #f6e58d;
    border: 1px solid #e1c547;
}

.input.grey {
    background-color: #ddd;
    border: 1px solid #bbb;
}

.input.grey.full-width {
    flex: 9;
}

.input select,
.input input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input input:focus,
.input select:focus {
    border-color: #007bff;
    outline: none;
    background-color: #fff;
}

.unit-label {
    width: 50%;
    font-size: 12px;
    color: #666;
    background: transparent;
    padding-left: 8px;
    text-align: left;
    display: block;
}

.action-year-select {
    background-color: #ddd;
    border: 1px solid #bbb;
    font-size: 14px;
    border-radius: 4px;
}

.action-year-remark {
    font-size: 12px;
    color: #666;
    line-height: 1.5;
    display: flex;
    align-items: center;
}

/*.freight-tool-chart-container {*/
/*    display: flex;*/
/*    justify-content: space-around;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*    padding: 20px;*/
/*    border-radius: 5px;*/
/*    border: 1px solid #ddd;*/
/*}*/

/*.freight-tool-chart-box {*/
/*    width: 45%;*/
/*    height: 300px;*/
/*}*/

.freight-tool-chart-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px; /* Rounds the corners */
    margin-bottom: 40px; /* Adds extra space below */
}

.freight-tool-chart-box {
    width: 45%;
    height: 300px;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
}

.freight-tool-button-container {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 8px;
}

.freight-tool-reset-button, .freight-tool-save-button {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.freight-tool-reset-button {
    background-color: #4CAF50;
    color: white;
}

.freight-tool-save-button {
    background-color: #4CAF50;
    color: white;
}

.freight-tool-reset-button:hover,
.freight-tool-save-button:hover {
    background-color: #2E7D32;
}

.net-zero-report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}


@media (max-width: 768px) {
    .absolute-target-section {
        width: 100%;
    }

    .table-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .label {
        flex: 1;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .input {
        flex: 2;
    }

    .input input,
    .input select {
        font-size: 12px;
        padding: 6px;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}