.button {
  display: inline-block;
  color: grey;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition: opacity 0.3s ease;
}

.button:hover {
  opacity: 0.5;
}

