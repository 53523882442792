.customerManagementContainer {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    width: 90%;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .addButton {
    font-size: 16px;
    color: #d15533;
    font-weight: bold;
    background-color: transparent;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 0.5s ease;
  }
  
  .addButton:hover {
    opacity: 0.5;
    background-color: transparent;
  }
  
  .section {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .field {
    margin-bottom: 10px;
  }
  
  .label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    background-color: #f5f5f5;
    border: 1px solid #cecece;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .input:focus {
    border-color: #d15533;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }
  
  .button {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #d15533 !important;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.5s ease;
  }
  
  .button:hover {
    opacity: 0.5;
  }
  
  .iconButton {
    padding: 8px 8px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: opacity 0.5s ease;
    margin-top: -3px;
  }
  
  .iconButton:hover {
    opacity: 0.5;
    background: transparent;
  }
  
  .customerDetailsHeader {
    display: flex;
    justify-content: space-around;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    width: 100%;
    background: #f0f0f0;
    padding: 10px;
    padding-right: 150px;
  }
  
  .detailsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .customerItem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    padding-left: 25px;    
  }
  
  .customerDetails {
    display: contents;
  }
  
  .customerItem div {
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .divider {
    margin: 20px 0;
    border-bottom: 2px solid #ccc;
  }
  
  .addressField {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .removeIcon {
    margin-left: 10px;
    cursor: pointer;
    color: #b9b2b2;
    font-size: 18px;
  }

  .removeIcon:hover {
    opacity: 0.5;
  }

  